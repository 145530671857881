<template>
  <div ref="dropdownRef">
    <el-dropdown ref="dropdownentityRef">
      <el-dropdown-menu slot="dropdown">
        <div class="dropdownMenu">
          <ul v-for="item in courseType" :key="item.key">
            <div class="title">
              <span>
                {{ item.value }}
              </span>
              <div class="btn" @click.stop="handleTypeShow(item)">
                {{ item.checkAll ? '全部隐藏' : '全部展示' }} 
              </div>
            </div>
            <li  
              v-for=" (child, index) in item.detail" 
              :key="item.key + child.key" 
              :draggable="true"
              @dragstart="dragstartHandler(index,item)"
              @dragover="dragoverHandler(index,item)"
              @dragend="dragendHandler"
            >
              <div class="left">
                <i class="el-icon-menu icon"></i>
                <!-- <HolderOutlined  class="icon" /> -->
                {{ child.value }}
              </div>
              <i class="el-icon-view icons" v-if="child.check" @click.stop="()=>{handleShowitem(child)}"></i>
              <i class="el-icon-view icons gray" v-else @click.stop="()=>{handleShowitem(child)}"></i>
              <!-- <Icon icon="ant-design:eye-filled" class="icons" v-if="child.check" @click.stop="()=>{handleShowitem(child)}" />
              <Icon icon="ant-design:eye-invisible-filled" class="icons gray" @click.stop="()=>{handleShowitem(child)}" v-else /> -->
            </li>
          </ul>
        </div>
      </el-dropdown-menu >
      <div class="dropDownTitlt" @click="handleShow">
        信息展示
        <i class="el-icon-arrow-down img"></i>
        <!-- <DownOutlined class="img" /> -->
      </div>
    </el-dropdown>
  </div>
</template>

<script>
 import { useEventBus } from '@/utils/bus'
 import { COURSE_STATUS , EcourseType, COURSE_Type } from '@/enums/common.js'
  export default {
    name:'',
    data(){
      return{
        Bus:useEventBus(),
        courseType:JSON.parse(JSON.stringify(COURSE_Type)),
        TeacherCalendatInformation:localStorage.getItem('TeacherCalendatInformation'),
        dragIndex:-1,
        tsCourseType:undefined
      }
    },
    mounted () {
      if(this.TeacherCalendatInformation){
        this.courseType = JSON.parse(this.TeacherCalendatInformation)
      }
      this.setLocal()
      // document.addEventListener('click',(e)=>{
      //   if(this.$refs.dropdownRef){
      //     this.dropDownShow = this.$refs.dropdownRef.contains(e.target) ? true : false
      //   }
      // })
    },
    destroyed () {
      document.removeEventListener('click',()=>{})
    },
    methods: {
      handleRest(){
        // this.dropDownShow = false
        localStorage.removeItem('TeacherCalendatInformation')
        this.TeacherCalendatInformation = '[]'
        this.courseType = JSON.parse(JSON.stringify(COURSE_Type))
        this.setLocal()
      },
      handleShow(){
        // this.dropDownShow = true
      },
      handleShowitem(obj){
        obj.check = !obj.check
        this.setLocal()
        this.Bus.$emit('courseType',this.courseType)
      },
      handleTypeShow(courseTypeObj){
        courseTypeObj.checkAll = !courseTypeObj.checkAll
        courseTypeObj.detail.forEach(item=>{
          item.check = courseTypeObj.checkAll
        })
        this.setLocal()
        this.Bus.$emit('courseType',this.courseType)
      },
      setLocal(){
        localStorage.setItem('TeacherCalendatInformation',JSON.stringify(this.courseType))
      },
      dragstartHandler (index,courseType){
        this.dragIndex = index;
        this.tsCourseType = courseType
      },
      dragoverHandler (index, courseType){
        if( this.tsCourseType.key !== courseType.key) return
        if ( this.dragIndex !== -1 &&  this.dragIndex !== index) {
          const draggedItem = courseType.detail.splice( this.dragIndex, 1)[0];
          courseType.detail.splice(index, 0, draggedItem);
          this.dragIndex = index;
        }
      },
      dragendHandler(){
        this.Bus.$emit('courseType',this.courseType)
        this.setLocal()
        this.dragIndex = -1;
        this.tsCourseType = undefined
      }
    }
  }
</script>

<style lang="less" scoped>
  .dropdownMenu{
    width: 150px;
    background: #fff;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 2px 8px #00000026;
    ul{
      width: 100%;
      list-style: none;
      user-select:none;
      .title{
        padding: 0 10px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
          font-size: 14px;
          color: #000;
          font-weight: bold;
        }
        .btn{
          font-size: 12px;
          color: #0960bd;
          cursor: pointer;
        }
      }
      li{
        padding: 0 5px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover{
          box-shadow: 0 2px 8px #00000026;
        }
        .icons{
          cursor: pointer;
        }
        .left{
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #3f3f3f;
          .icon{
            margin-right: 5px;
          }
        }
      }
    }
  }
  .dropDownTitlt{
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    color: #d4d4d4;
    cursor: pointer;
    font-size: 12px;
    .img{
      font-size: 14px;
    }
  }
  .gray{
    color: #ccc;
  }
  /deep/ .el-dropdown{
    width: 100%;
  }
</style>