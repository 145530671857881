<template>
  <div class="monthSchedule" ref="courseCalendar">
    <monthScheduleTop
      :calendarParameter="calendarParameter"
      :courseCalendar="$refs.courseCalendar"
      @handleRenewal="handlefiltrateRenewal"
      @handleApiRenewal="handleApiRenewal"
      @fullscreen="fullscreen"
    />
    <monthScheduleFiltrate
      ref="filtrateRef"
      @timeFiltrateData="timeFiltrateData"
      @handleRenewal="handlefiltrateRenewal"
      @userFiltrateData="userFiltrateData"
    />
    <calendar
      v-if="calendarShow"
      :class="[!isFullscreen ? 'calendarBox' : 'calendarFullBox']"
      :calendarType="2"
      ref="calendarRef"
    />
    <editDialog ref="editDialogRef" @handleRenewal="handleRenewal" />
  </div>
</template>

<script>
  import monthScheduleTop from './components/top.vue'
  import monthScheduleFiltrate from './components/filtrate.vue'
  import calendar from '@/components/Calendar_2/index.vue'
  import editDialog from './components/editDialog.vue'
  import { EtimeInterval, EcourseType, COURSE_STATUS } from '@/enums/common.js'
  import {
    getCalendarCourse,
    getTeacherAuction,
    getTeacherMakeUp,
    getPrivateCalendarCourse, getTeacherTrial
  } from '@/api/course/course'
  import moment from 'moment'

  export default {
    name: 'monthSchedule_2',
    components: {
      monthScheduleTop,
      monthScheduleFiltrate,
      calendar,
      editDialog
    },
    data () {
      return {
        isFullscreen: false,
        studentId: 0,
        calendarParameter: {
          timestamp: moment().unix()
        },
        dataList: [],
        calendarShow: true,
        TearchCalendar: JSON.parse(localStorage.getItem('TearchCalendar') || '{}')
      }
    },
    methods: {
      fullscreen ( _fullscreen ) {
        this.isFullscreen = _fullscreen
      },
      async handleDateSelect ( dateObj ) {
        console.log('点击日期方格触发', dateObj)
        let record = {
          date: [moment.unix(dateObj.timestamp).format()]
        }
        this.$refs.editDialogRef.setDialogShow(true, record)
        // await commonStore.getTeacherStudenAction(params)

        // openModal(true, {
        //   isUpdate: false,
        //   timestamp: dateObj.timestamp
        // })
        return
      },
      handleEventClick ( info ) {
        console.log(info)
        // if (info.event._def.extendedProps.remark) return
        // let dateId = info.event._def.publicId
        // editModal(true, {
        //   isUpdate: true,
        //   dateId
        // })
        return
      },
      // handleSuccess() {
      //   handleApiRenewal()
      // },
      handleDbclickCell ( dateObj ) {
        this.handleDateSelect(dateObj)
      },
      handlefiltrateRenewal () {
        this.calendarShow = false
        this.$nextTick(() => {
          this.calendarShow = true
          this.$nextTick(() => {
            this.handleRenewal()
          })
        })
      },
      handleClickTag ( tagItem ) {
        if (tagItem.type == COURSE_STATUS.cancel) {
          let record = {
            date: [moment.unix(tagItem.started_at).format()],
            started_at: moment.unix(tagItem.started_at).format('HH:mm:00'),
            ended_at: moment.unix(tagItem.ended_at).format('HH:mm:00'),
            edit: true,
            id: tagItem.id,
            remark: tagItem.remark
          }
          this.$refs.editDialogRef.setDialogShow(true, record)
        }

        // if (tagItem.type !== EcourseType.course1v1) return
        // editModal(true, {
        //   isUpdate: true,
        //   dateId: tagItem.id,
        //   timestamp: tagItem.taught_at || tagItem.started_at
        // })
      },
      handleApiRenewal () {
        this.calendarShow = false
        this.$nextTick(() => {
          this.calendarShow = true
          this.$nextTick(() => {
            this.$refs.filtrateRef.restFiltrateCondition()
            this.studentId = 0
            localStorage.removeItem('TearchCalendar')
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ] = null
            this.handleRenewal(true)
          })
        })
      },
      handleRenewal ( flg ) {
        !flg
          ? this.$refs.calendarRef.handleRenewal(
            this.calendarParameter.timestamp,
            this.renewal
          )
          : this.renewal()
      },
      renewal () {
        let _this = this
        this.dataList = []
        this.TearchCalendar = JSON.parse(
          localStorage.getItem('TearchCalendar') || '{}'
        )
        let checkCollect = this.$refs.filtrateRef.getCheckCollect()
        this.get1v1Data(() => {
          this.filter1v1(checkCollect.soloCheckList)
          this.getClassData(() => {
            this.fillClass(checkCollect.checkClass)
            this.getVacate(() => {
              _this.fillVacate(checkCollect.checkVacate)
              _this.fillMakeUp(checkCollect.checkMakeUp)
              _this.fillTrial(checkCollect.trial)
              let timeArea = _this.$refs.filtrateRef.getCheckTimeArea()
              console.log(_this.dataList)
              timeArea.length > 0
                ? (_this.dataList = _this.timeFiltrate(timeArea, _this.dataList))
                : ''
              _this.studentId ? _this.userFilter(_this.studentId) : ''
              _this.$refs.calendarRef.setFillData(_this.dataList)
            })
          })
        })
      },
      filter1v1 ( soloCheckList ) {
        if (soloCheckList.length === 0) {
          this.dataList =
            this.dataList.filter(child => child.type !== EcourseType.course1v1) ||
            []
          this.studentId ? this.userFilter(this.studentId) : ''
          return
        }
        if (
          this.TearchCalendar &&
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          this.dataList = this.dataList || []
          this.dataList = this.dataList.concat(
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child => {
              return (
                child.type === EcourseType.course1v1 &&
                soloCheckList.includes(child.status)
              )
            })
          )
          this.studentId ? this.userFilter(this.studentId) : ''
        }
      },
      fillVacate ( flg ) {
        if (!flg) {
          this.dataList =
            this.dataList.filter(child => child.type !== EcourseType.vacate) ||
            []
          return
        }
        if (
          this.TearchCalendar &&
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          this.dataList = this.dataList || []
          this.dataList = this.dataList.concat(
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child => {
              return child.type === EcourseType.vacate
            })
          )
        }
      },
      fillMakeUp ( flg ) {
        if (!flg) {
          this.dataList =
            this.dataList.filter(child => child.type !== EcourseType.makeUp) ||
            []
          return
        }
        if (
          this.TearchCalendar &&
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          this.dataList = this.dataList || []
          this.dataList = this.dataList.concat(
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child => {
              return child.type === EcourseType.makeUp
            })
          )
        }
      },
      fillTrial ( flg ) {
        if (!flg) {
          this.dataList =
            this.dataList.filter(child => child.type !== EcourseType.trial) ||
            []
          return
        }
        if (
          this.TearchCalendar &&
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          this.dataList = this.dataList || []
          this.dataList = this.dataList.concat(
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child => {
              return child.type === EcourseType.trial
            })
          )
        }
      },
      fillClass ( flg ) {
        if (!flg) {
          this.dataList =
            this.dataList.filter(child => child.type !== EcourseType.class) || []
          return
        }

        if (
          this.TearchCalendar &&
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          this.dataList = this.dataList || []
          this.dataList = this.dataList.concat(
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child => {
              return child.type === EcourseType.class
            })
          )
        }
      },
      setLocalstorage ( res ) {
        if (res && res.length > 0) {
          let _ts =
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ] || []
          _ts = _ts.concat(res)
          if (_ts.length === 0) return
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ] = _ts
          // localStorage.setItem('TearchCalendar', JSON.stringify(this.TearchCalendar))
        }
      },
      flattenObject ( obj, parentKey ) {
        const result = {}
        for (const key in obj) {
          const newKey = parentKey ? `${parentKey}_${key}` : key
          if (typeof obj[ key ] === 'object' && obj[ key ] !== null) {
            const nestedObj = flattenObject(obj[ key ], newKey)
            Object.assign(result, nestedObj)
          } else {
            result[ newKey ] = obj[ key ]
          }
        }
        return result
      },
      async get1v1Data ( callback ) {
        let _tsData = []
        if (
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          _tsData =
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child => child.type === EcourseType.course1v1) || []
        }

        if (_tsData.length > 0) {
          callback ? callback() : ''
          return
        }
        getPrivateCalendarCourse(
          {
            started_at: moment
              .unix(this.calendarParameter.timestamp)
              .startOf('month')
              .format('YYYY-MM-DD 00:00:00'),
            ended_at: moment
              .unix(this.calendarParameter.timestamp)
              .endOf('month')
              .format('YYYY-MM-DD 23:59:59'),
            is_reserved: 1,
            calendar_scope: 1
          },
          res => {
            this.dataList = this.dataList || []
            res = res.map(item => {
              item.type = EcourseType.course1v1
              // if (
              //   item.detail &&
              //   item.detail.user &&
              //   toString.call(item.detail.user) === '[object Object]'
              // ) {
              //   item.detail = this.flattenObject(item.detail)
              // }
              return item
            })
            this.setLocalstorage(res)
            callback ? callback() : ''
          }
        )
      },
      async getClassData ( callback ) {
        let _tsData = []
        if (
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          _tsData =
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child => child.type === EcourseType.class) || []
        }

        if (_tsData.length > 0) {
          callback ? callback() : ''
          return
        }
        getCalendarCourse(
          {
            started_at: moment
              .unix(this.calendarParameter.timestamp)
              .startOf('month')
              .format('YYYY-MM-DD 00:00:00'),
            ended_at: moment
              .unix(this.calendarParameter.timestamp)
              .endOf('month')
              .format('YYYY-MM-DD 23:59:59'),
            is_reserved: 1
          },
          res => {
            res = res || []
            res = res.map(item => {
              item.type = EcourseType.class
              return item
            })
            this.setLocalstorage(res)
            callback ? callback() : ''
          }
        )
      },
      async getVacate ( callback ) {
        let _tsData = []
        if (
          this.TearchCalendar[
            moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
            ]
        ) {
          _tsData =
            this.TearchCalendar[
              moment.unix(this.calendarParameter.timestamp).format('YYYY/MM')
              ].filter(child => child.type === EcourseType.vacate || child.type === EcourseType.makeUp) || []
        }

        if (_tsData.length > 0) {
          callback ? callback() : ''
          return
        }
        const makeUpData = await getTeacherMakeUp({
          date: [moment
            .unix(this.calendarParameter.timestamp)
            .startOf('month')
            .format('YYYY-MM-DD 00:00:00'), moment
            .unix(this.calendarParameter.timestamp)
            .endOf('month')
            .format('YYYY-MM-DD 23:59:59')],
        }, makeUpRes => {
          let arr = []
          makeUpRes.forEach(item => {
            item.type = EcourseType.makeUp
            arr.push(item)
          })
          return [...arr]
        })
        const trialData = await getTeacherTrial({
          date: [moment
            .unix(this.calendarParameter.timestamp)
            .startOf('month')
            .format('YYYY-MM-DD 00:00:00'), moment
            .unix(this.calendarParameter.timestamp)
            .endOf('month')
            .format('YYYY-MM-DD 23:59:59')],
        }, makeUpRes => {
          let arr = []
          makeUpRes.forEach(item => {
            item.type = EcourseType.trial
            arr.push(item)
          })
          return [...arr]
        })

        await getTeacherAuction(
          '',
          'get',
          {
            started_at: moment
              .unix(this.calendarParameter.timestamp)
              .startOf('month')
              .format('YYYY-MM-DD 00:00:00'),
            ended_at: moment
              .unix(this.calendarParameter.timestamp)
              .endOf('month')
              .format('YYYY-MM-DD 23:59:59')
          },
          res => {
            res = res || []
            res = res.map(item => {
              item.type = EcourseType.vacate
              return item
            })
            res = res.concat(makeUpData || [], trialData || [],)
            this.setLocalstorage(res)
            console.log(res)
            callback ? callback() : ''
          }
        )
      },
      timeFiltrateData ( objArr, flg ) {
        this.renewal()
        if (!objArr || objArr.length === 0) return
        this.dataList = this.timeFiltrate(objArr, this.dataList)
        if (!flg) {
          this.studentId ? this.userFilter(this.studentId) : ''
          this.$refs.calendarRef.setFillData(this.dataList)
        }
      },
      timeFiltrate ( objArr, filtrateBefor ) {
        let tsData = []
        objArr.map(obj => {
          let tsSection =
            EtimeInterval.filter(child => child.key === obj)[ 0 ] || undefined
          if (!tsSection) return

          tsData = tsData.concat(
            filtrateBefor.filter(item => {
              let taught_at = item.taught_at || item.started_at
              let taught_finish_at = item.taught_finish_at || item.ended_at
              return (
                taught_at &&
                taught_finish_at &&
                moment(
                  moment.unix(taught_at).format('HH:mm'),
                  'HH:mm'
                ).isSameOrAfter(moment(tsSection.start, 'HH:mm')) &&
                moment(moment.unix(taught_at).format('HH:mm'), 'HH:mm').isBefore(
                  moment(tsSection.end, 'HH:mm')
                )
              )
            })
          )
        })
        return tsData.filter(
          ( item, index ) => tsData.findIndex(i => i.id === item.id) === index
        )
      },
      userFiltrateData ( val ) {
        this.studentId = val
        this.renewal()
        if (val) {
          this.userFilter(val)
          this.$refs.calendarRef.setFillData(this.dataList)
          return
        }
      },
      userFilter ( val ) {
        // this.dataList = this.dataList.filter((child) => {
        //   return child['detail'] && child['detail'].user_id === val
        // })
      },
      mouseUpEvent ( activeArr ) {
        if (!(activeArr[ 0 ] && activeArr[ 0 ].timestamp)) return
        let record = {
          date: activeArr.map(item => moment.unix(item.timestamp).format())
        }
        this.$refs.editDialogRef.setDialogShow(true, record)
        return
      },
      getCalendarData () {
      }
    },
    destroyed () {
      localStorage.removeItem('TearchCalendar')
    },
    mounted () {
      localStorage.removeItem('TearchCalendar')
    }
  }
</script>

<style scoped lang="less">
  .monthSchedule {
    background: #fff;
  }

  .calendarBox {
    width: 100%;
    // height: calc(100vh - 450px);
  }

  .calendarFullBox {
    width: 100%;
    height: calc(100% - 100px);
  }
</style>
<style lang="less">
  .fullScreens {
    position: fixed;
    left: 200px;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 999;
  }
</style>
