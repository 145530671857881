<template>
  <dl class="filtrate">
    <dt>
      <el-checkbox
        class="checkAll"
        v-model="checkCollect.checkAll"
        :indeterminate="indeterminate"
        @change="onCheckAllChange"
      >
        全选
      </el-checkbox>
      <div class="checkAll solo">
        <el-checkbox
          v-model="checkCollect.checkAllSolo"
          :indeterminate="indeterminate"
          @change="onCheckAllSoloChange"
        >
          1v1课程
        </el-checkbox>
        <i class="el-icon-d-arrow-right doubleRightOutlined"></i>
        <CheckboxGroup
          :soloOptions="soloOptions"
          :checkCollect="checkCollect"
          @checkAll="soloCheckAll"
          ref="checkboxGroupRef"
        />
      </div>
      <el-checkbox
        class="checkAll class"
        v-model="checkCollect.checkClass"
        :indeterminate="indeterminate"
        @change="
          () => {
            onCheckChange(EcourseType.class);
          }
        "
      >
        班课
      </el-checkbox>
      <el-checkbox
        v-model="checkCollect.checkVacate"
        :indeterminate="indeterminate"
        @change="
          () => {
            onCheckChange(EcourseType.vacate);
          }
        "
      >
        <span style="color: #bebebe">
        可上课时间
        </span>
      </el-checkbox>
      <el-checkbox
        v-model="checkCollect.checkMakeUp"
        :indeterminate="indeterminate"
        @change="
          () => {
            onCheckChange(EcourseType.makeUp);
          }
        "
      >
        <span
          style="color: #8181fe"
        >
        补课时间
        </span>
      </el-checkbox>
      <el-checkbox
        v-model="checkCollect.trial"
        :indeterminate="indeterminate"
        @change="
          () => {
            onCheckChange(EcourseType.trial);
          }
        "
      >
        <span
          style="color: #bfbf00"

        >
                追加上课时间

        </span>
      </el-checkbox>
    </dt>
    <dd>
      <div class="dropdown">
        <Dropdowns @handleRenewal="handleRenewal" ref="dropdownRef" />
      </div>
      <!-- <BasicForm v-bind="$attrs" @register="registerHeaderForm" class="formBox" /> -->
      <!-- <a-button type="primary">添加</a-button> -->
    </dd>
  </dl>
</template>

<script>
  import { COURSE_STATUS, EcourseType } from '@/enums/common.js'
  import CheckboxGroup from './checkboxGroup.vue'
  import Dropdowns from './dropdown.vue'

  export default {
    name: 'filtrate',
    data () {
      return {
        checkCollect: {
          checkAll: false,
          checkVacate: false,
          checkClass: false,
          checkAllSolo: false,
          checkMakeUp: false,
          trial: false,
          soloCheckList: [COURSE_STATUS.subscribe]
        },
        timeArea: [],
        TeacherCalendatCondition: localStorage.getItem(
          'TeacherCalendatCondition'
        ),
        indeterminate: false,
        soloOptions: [
          {
            label: '已预约',
            value: COURSE_STATUS.subscribe,
            color: '#FFC74F',
            tagColor: 'yellow'
          },
          {
            label: '已完成',
            value: COURSE_STATUS.accomplish,
            color: '#29D087',
            tagColor: 'green'
          },
          {
            label: '已取消',
            value: COURSE_STATUS.cancel,
            color: '#FF6A6A',
            tagColor: 'red'
          }
        ],
        EcourseType
      }
    },
    components: {
      CheckboxGroup,
      Dropdowns
    },
    mounted () {
      if (this.TeacherCalendatCondition) {
        this.checkCollect = JSON.parse(this.TeacherCalendatCondition)
      }
      this.$nextTick(() => {
        this.$emit('handleRenewal')
      })
    },
    methods: {
      onCheckAllChange () {
        this.$nextTick(() => {
          this.checkCollect.checkAllSolo = this.checkCollect.checkAll
          this.checkCollect.checkClass = this.checkCollect.checkAll
          this.checkCollect.checkVacate = this.checkCollect.checkAll
          this.checkCollect.checkMakeUp = this.checkCollect.checkAll
          this.checkCollect.trial = this.checkCollect.checkAll
          this.onCheckAllSoloChange()
        })
      },
      onCheckAllSoloChange () {
        this.checkCollect.soloCheckList = !this.checkCollect.checkAllSolo
          ? []
          : [
            COURSE_STATUS.subscribe,
            COURSE_STATUS.accomplish,
            COURSE_STATUS.cancel
          ]
        this.judgecheckAll()
        this.$refs.checkboxGroupRef.restStatus()
        // this.$forceUpdate()
        this.$emit('handleRenewal', true)
      },
      soloCheckAll ( flg ) {
        this.checkCollect.checkAllSolo = flg
        this.judgecheckAll()
        this.$emit('handleRenewal', true)
      },
      onCheckChange () {
        this.judgecheckAll()
        this.$emit('handleRenewal', true)
      },
      judgecheckAll () {
        this.checkCollect.checkAll =
          this.checkCollect.checkAllSolo &&
          this.checkCollect.checkClass &&
          this.checkCollect.checkVacate &&
          this.checkCollect.checkMakeUp &&
          this.checkCollect.trial
            ? true
            : false
        this.setLocal()
      },
      setLocal () {
        localStorage.setItem(
          'TeacherCalendatCondition',
          JSON.stringify(this.checkCollect)
        )
      },
      handleRenewal () {
        this.$emit('handleRenewal')
      },
      getCheckCollect () {
        return this.checkCollect
      },
      getCheckTimeArea () {
        return this.timeArea
      },
      setCheckCollect ( key, val ) {
        this.checkCollect[ key ] = val
      },
      restFiltrateCondition () {
        this.timeArea = []
        this.$refs.dropdownRef.handleRest()
      }
    }
  }
</script>

<style lang="less" scoped>
  .filtrate {
    height: 40px;
    padding: 0 20px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    :deep(.ant-checkbox + span) {
      padding-left: 0;
    }

    dt {
      height: 100%;
      display: flex;
      flex: auto;
      align-items: center;

      .checkAll {
        padding-right: 16px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          right: 12px;
          top: 50%;
          margin-top: -10px;
          width: 1px;
          height: 20px;
          background: #eee;
        }
      }

      .yellow {
        :deep(.ant-checkbox-inner) {
          border-color: #ffc74f;
        }
      }

      .green {
        :deep(.ant-checkbox-inner) {
          border-color: #29d087;
        }
      }

      .red {
        :deep(.ant-checkbox-inner) {
          border-color: #ff6a6a;
        }
      }

      .doubleRightOutlined {
        font-size: 12px;
        margin-right: 5px;
      }

      .class {
        color: #108ee9;

        :deep(.ant-checkbox-inner) {
          border-color: #108ee9;
        }
      }
    }

    dd {
      width: 10%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 0;

      :deep(.ant-form-item) {
        margin-bottom: 0;
      }

      .dropdown {
        width: 150px;
        height: 32px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #eeeeee;
        border-radius: 2px;
        margin-right: 10px;
        z-index: 1;
      }

      .formBox {
        flex: auto;
        margin-right: 10px;
      }
    }

    :deep(.ant-checkbox-wrapper) {
      font-size: 12px;
    }
  }

  .solo {
    display: flex;
    align-items: center;
  }
</style>
