<template>
  <el-dialog
    title="添加教师安排"
    :visible.sync="dialogVisible"
    width="576px">
    <el-form ref="form" :model="formData" label-width="120px" :rules="rules">
      <el-form-item label="开始时间" prop="started_at">
        <el-time-picker
          size="small"
          v-model="formData.started_at"
          value-format="HH:mm:00"
          :disabled="edit"
          format="HH:mm:00"
          placeholder="请选择开始时间">
        </el-time-picker>
      </el-form-item>
      <el-form-item label="结束时间" prop="ended_at">
        <el-time-picker
          size="small"  
          :disabled="edit"
          v-model="formData.ended_at"
          value-format="HH:mm:00"
          format="HH:mm:00"
          placeholder="请选择结束时间">
        </el-time-picker>
      </el-form-item>
      <el-form-item label="日期" prop="date">
        <el-date-picker
          size="small"
          :disabled="edit"
          v-model="formData.date"
          type="dates"
          placeholder="请选择一个或多个日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input size="small" :disabled="edit" type="textarea" v-model="formData.remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="!edit">
      <el-button @click="handleClose" size="small"  >取 消</el-button>
      <el-button type="primary" @click="handleSubmit" size="small"  >确 定</el-button>
    </span>
    <span slot="footer" class="dialog-footer" v-else>
      <el-button @click="handleClose" size="small"  >取 消</el-button>
      <el-button type="primary" @click="handleDel" size="small"  >删 除</el-button>
    </span>
</el-dialog>
</template>

<script>
  import moment from 'moment'
  import { getTeacherAuction } from '@/api/course/course'
  export default {
    name:'editDialog',
    data(){
      return {
        dialogVisible:false,
        formData:{
          started_at:'09:00:00',
          ended_at:'17:30:00',
          date:[],
          remark:''
        },
        rules: {
          started_at: [
            { required: true, message: '请输入开始时间', trigger: 'blur' },
          ],
          ended_at: [
            { required: true, message: '请输入结束时间', trigger: 'blur' },
          ],
          date: [
            { required: true, message: '请选择开始日期和结束日期', trigger: 'blur' },
          ],
          remark: [
            { required: true, message: '请输入备注', trigger: 'blur' },
          ],
        },
        edit:false
      }
    },
    methods: {
      setDialogShow(flg,dataObj){
        this.edit = dataObj.edit
        this.dialogVisible = flg
        dataObj.date ? this.formData.date = dataObj.date : ''
        dataObj.started_at ? this.formData.started_at = dataObj.started_at : ''
        dataObj.ended_at ? this.formData.ended_at = dataObj.ended_at : ''
        this.formData.remark = dataObj.remark || ''
        dataObj.id ? this.formData.id = dataObj.id : ''
      },
      handleClose(){
        this.$refs.form.resetFields()
        this.dialogVisible = false
        // this.formData.remark = ''
        // this.$refs.form.resetFields()
      },
      handleSubmit(){
        
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.formData.date = [...new Set(this.formData.date)]
            
            this.formData.date = this.formData.date.map(item=>{
              item = moment(item).format('YYYY-MM-DD')
              return item
            })
            getTeacherAuction('', 'post', this.formData, () => {
              this.$notify({
                title: '提示',
                message: '操作成功',
                type: 'success'
              });
              this.$emit('handleRenewal')
              this.handleClose()
            })
          }
        })
      },
      handleDel(){
        getTeacherAuction(this.formData.id, 'delete', {}, (res) => {
            this.$notify({
              title: '提示',
              message: '删除成功',
              type: 'success'
            });
            this.$emit('handleRenewal')
            this.handleClose()
        })
      }
    }
  }
</script>

<style lang="less" scoped>


</style>